<template>
  <div class="news-write" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <span>최근 소식</span>
      </div>
      <div class="desc font-sm">
        <span>프로젝트의 최근 소식을 작성해주세요.</span>
      </div>
      <div class="form">
        <div class="subject font-md">
          <input :id="`${component.name}Title`" type="text" class="form-control border-focus-point font-sm" placeholder="제목을 입력해주세요." v-model="state.form.title" @keyup.enter="submit()"/>
        </div>
        <div class="content font-sm">
          <TinyEditor :value.sync="state.form.content" v-if="state.loaded" :height="420"/>
        </div>
      </div>
      <ul class="tight alert alert-secondary">
        <li>문장 사이 간격이 넓을 경우 간격은 shift + enter 키를 사용해주세요.</li>
        <li>타 파일에서 문장을 복사, 붙여넣기 전 메모장 을 열어 붙여넣고 다시 카피하여 붙여넣으세요.</li>
        <li>이미지는 680픽셀에 맞춰 조정되므로 너무 작은 이미지의 경우 선명하지 않게 보일 수 있으니 참고해주세요.</li>
        <li>삽입 가능한 이미지 파일은 1개 파일 당 5MB 입니다.</li>
      </ul>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">작성하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import TinyEditor from "@/components/TinyEditor";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import router from "@/scripts/router";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalNewsWrite";
  this.initialize = initialize;
}

export default defineComponent({
  components: {TinyEditor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams?.newsSeq) {
        state.loaded = false;
        http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news/${modalParams.newsSeq}`).then(({data}) => {
          state.loaded = true;
          state.form.title = data.body.title;
          state.form.content = data.body.content;
        });
      }

      store.commit("tuneModal", {component, size: "xm"});
    });

    const state = reactive({
      loaded: true,
      form: {
        content: "",
        title: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = async () => {
      const args = lib.getRenewed(state.form);

      if (!args.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      } else if (!args.content?.trim()) {
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      }

      const infoDocument = new DOMParser().parseFromString(args.content, "text/html");
      const images = infoDocument.getElementsByTagName("img");
      const base64Images = [];

      for (let i = 0; i < images.length; i += 1) {
        if (images[i].src.startsWith("data:image/")) {
          base64Images.push(images[i]);
        }
      }

      if (modalParams?.newsSeq) {
        const res = await http.put(`/api/maker/project/${router.app.$route.params.projectType}/${modalParams.projectSeq}/news/${modalParams.newsSeq}`, args).catch(httpError());

        if (res.error) {
          return;
        }

        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "최근 소식을 수정하였습니다.");
          }
        });
      } else {
        const res = await http.post(`/api/maker/project/${router.app.$route.params.projectType}/${router.app.$route.params.projectSeq}/news`, args).catch(httpError());

        if (res.error) {
          return;
        }

        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "최근 소식을 등록하였습니다.");
          }
        });
      }
    };

    return {component, state, submit};
  }
});
</script>

<style lang="scss" scoped>
.news-write {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .subject input {
        color: #000;
      }

      .content {
        background: $colorBackground;
        margin-top: $px15;
        height: $px420;
      }
    }

    > .alert {
      margin-top: $px15;
      padding: $px15;
      border: $px1 solid #eee;
      border-radius: $px4;

      > li {
        position: relative;
        padding-left: $px13;

        &:before {
          content: "-";
          position: absolute;
          left: 0;
          top: 0;
          line-height: 1.4;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }

  &.skeleton {
    > .wrapper {
      > .title > span,
      > .desc > span,
      .form .subject input {
        @include skeleton;
      }

      .content::v-deep {
        height: $px420;
        @include skeleton;

        * {
          visibility: hidden;
        }
      }
    }

    .action .btn {
      @include skeleton;
    }
  }
}
</style>